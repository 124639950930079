"use client"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Box, SvgIcon, Typography } from "@mui/material"
import { usePathname, useRouter } from "next/navigation"

import { useMemo, useState } from "react"
import styles from "./NavBar.module.css"
import { TNavItem, cleansNav, maintenanceNav, navData as cacheNavData, availabilityCalendarNav } from "./navData"
import { GenerateNick } from "@/util-functions/CommonFunctions"
import useUserDetails from "@/hooks/useUserDetails"
import { UserDetails } from "@/interfaces/UserDetails"
import useRoles from "@/hooks/useRoles"
import { useReadLocalStorage } from "usehooks-ts"
import Link from "next/link"
import SidePanel from "../SidePanel/SidePanel"
import AvailabilityCalendar from "../AvailabilityCalendar/AvailabilityCalendar"

type TSideBar = {
  collapse: boolean
}

const AdminNavBar = (props: TSideBar) => {
  const { collapse } = props

  const validUser = useReadLocalStorage("accessToken")
  const { data: userDetailsData } = useUserDetails()
  const { isCleaner, isMaintain } = useRoles(userDetailsData?.data as UserDetails)

  const navData: { [key in string]: TNavItem[] } = useMemo(() => {
    let tempData: any = { ...cacheNavData }
    if (validUser && userDetailsData) {
      if (isCleaner) {
        tempData["Task"] = [{ ...cleansNav }]
      }

      if (isMaintain) {
        tempData["Task"] = [{ ...maintenanceNav }]
      }
      //TODO:// Investigate reloading of web app when navigate to other screens 
      tempData[""] = [availabilityCalendarNav]
    }
    return tempData
  }, [isCleaner, isMaintain, userDetailsData, validUser])

  return (
    <Box className={styles.SideBarRoot}>
      {Object.keys(navData).map((key) => {
        return (
          <Box key={key} className={`${styles.SideBarSection} ${collapse ? styles.Collapsed : styles.Expanded}`}>
            {key && (
              <Box className={styles.SectionKey} title={key}>
                {collapse ? GenerateNick(key) : key}
              </Box>
            )}
            {navData?.[key]?.map((navItem, index) => {
              if (navItem?.tabs) {
                return <NestedNavItem key={`${index}navItems1`} navItem={navItem} collapse={collapse} />
              } else {
                return <NavItem key={`${index}navItems2`} navItem={navItem} collapse={collapse} />
              }
            })}
          </Box>
        )
      })}
    </Box>
  )
}

const NavItem = ({ navItem }: { navItem: TNavItem; collapse: boolean }) => {
  const router = useRouter()
  const pathname = usePathname()
  const [calendar, setCalendar] = useState<boolean>(false)
  const clickHandler = (e: any) => {
    e?.preventDefault()
    if (navItem.id === 'availability-calendar') {
      setCalendar(true)
      return
    }
    if (navItem.path) {
      router.push(navItem.path)
    }
  }
  const selected = pathname
    .split("/")
    .filter(Boolean)
    .includes(navItem?.path?.split("/")?.filter(Boolean)?.[0] || "")

  return (
    <>
      <Box onClick={clickHandler} className={`${styles.NavItem} ${styles[navItem.className ?? ""] ?? ""} ${selected ? styles.Selected : ""}`}
        title={navItem.text}>
        <Box className={styles.NavIcon}>
          <SvgIcon component={navItem.icon} />
        </Box>
        <Box className={styles.NavLabel}>{navItem.text}</Box>
      </Box>
      <SidePanel open={calendar} onClose={() => setCalendar(false)}>
        <AvailabilityCalendar />
      </SidePanel>
    </>
  )
}

const NestedNavItem = ({ navItem, collapse }: { navItem: TNavItem; collapse: boolean }) => {
  const pathname = usePathname()
  const router = useRouter()
  const [openMenu, setOpenMenu] = useState(false)
  const selected = pathname
    .split("/")
    .filter(Boolean)
    .includes(navItem?.path?.split("/")?.filter(Boolean)?.[0] || "")

  const toggleMenu = () => {
    setOpenMenu((prev) => !prev)
    console.log("triggered:")
    router.push(`${navItem.path}`)
  }
  return (
    <>
      <Box
        onClick={toggleMenu}
        sx={{
          display: "flex",
          mb: "1rem",
          borderTopRightRadius: "1.5rem",
          borderBottomRightRadius: "1.5rem",
          background: selected ? "rgba(27, 146, 10, 0.6)" : "transparent",

          minHeight: "3rem",
          alignItems: "center",
          width: "90%",
        }}
        key={`navItem2`}
      >
        <Box sx={{ minWidth: "80%", display: "flex" }}>
          <Box sx={{ ml: "2rem" }}>
            <SvgIcon component={navItem.icon} />
          </Box>
          <Typography
            sx={{
              fontWeight: "600",
              ml: "1.5rem",
              color: "#fff",
              display: { md: "none", lg: "block" },
            }}
          >
            {navItem.text}
          </Typography>
        </Box>
        <Box sx={{}}>
          <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
        </Box>
      </Box>
      {openMenu && (
        <Box sx={{ pl: "1rem" }}>
          {navItem.tabs &&
            navItem?.tabs.map((nestedItem: any, idx) => {
              return <NavItem key={`${idx}navItems44`} navItem={nestedItem} collapse={collapse} />
            })}
        </Box>
      )}
    </>
  )
}

export default AdminNavBar
