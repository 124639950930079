import { SvgIcon } from "@mui/material"

// Green = 44D400
// Grey = 084A4E
// Main = 3ACF01 // Color

const CleansIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
      <path
        d="M10.005 2.8352L18.0957 9.05136V19.6904H1.91436V9.05136L10.005 2.8352ZM10.005 0.417236L0 8.11441V21.6147H20V8.11441L10.005 0.417236Z"
        fill="inherit"
      />
      <mask id="mask0_348_968" maskUnits="userSpaceOnUse" x="4" y="5" width="12" height="13">
        <rect x="4" y="5.41724" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_348_968)">
        <path
          d="M5.5 16.9172V13.4172C5.5 12.7256 5.74383 12.1359 6.2315 11.6482C6.71883 11.1609 7.30833 10.9172 8 10.9172H8.5V6.91724C8.5 6.64224 8.598 6.40674 8.794 6.21074C8.98967 6.01507 9.225 5.91724 9.5 5.91724H10.5C10.775 5.91724 11.0105 6.01507 11.2065 6.21074C11.4022 6.40674 11.5 6.64224 11.5 6.91724V10.9172H12C12.6917 10.9172 13.2813 11.1609 13.769 11.6482C14.2563 12.1359 14.5 12.7256 14.5 13.4172V16.9172H5.5ZM6.5 15.9172H7.5V14.4172C7.5 14.2756 7.54783 14.1569 7.6435 14.0612C7.7395 13.9652 7.85833 13.9172 8 13.9172C8.14167 13.9172 8.2605 13.9652 8.3565 14.0612C8.45217 14.1569 8.5 14.2756 8.5 14.4172V15.9172H9.5V14.4172C9.5 14.2756 9.548 14.1569 9.644 14.0612C9.73967 13.9652 9.85833 13.9172 10 13.9172C10.1417 13.9172 10.2605 13.9652 10.3565 14.0612C10.4522 14.1569 10.5 14.2756 10.5 14.4172V15.9172H11.5V14.4172C11.5 14.2756 11.548 14.1569 11.644 14.0612C11.7397 13.9652 11.8583 13.9172 12 13.9172C12.1417 13.9172 12.2603 13.9652 12.356 14.0612C12.452 14.1569 12.5 14.2756 12.5 14.4172V15.9172H13.5V13.4172C13.5 13.0006 13.3542 12.6464 13.0625 12.3547C12.7708 12.0631 12.4167 11.9172 12 11.9172H8C7.58333 11.9172 7.22917 12.0631 6.9375 12.3547C6.64583 12.6464 6.5 13.0006 6.5 13.4172V15.9172ZM10.5 10.9172V6.91724H9.5V10.9172H10.5Z"
          fill="inherit"
        />
      </g>
    </svg>
  )
}

export default CleansIcon
