import SvgIcon from "@mui/material/SvgIcon"

const SideBarTrigger = () => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
        <g filter="url(#filter0_d_320_1343)">
          <rect x="8" y="6" width="40" height="40" rx="20" fill="white" />
          <path d="M36.3333 26L23 18L23 34L36.3333 26Z" fill="#373737" />
        </g>
        <defs>
          <filter id="filter0_d_320_1343" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_320_1343" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_320_1343" result="shape" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default SideBarTrigger
