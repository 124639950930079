import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Box, SxProps } from "@mui/material";
import { memo, useMemo } from "react";
import { ICustomDayProps } from "@/interfaces/AvailabilityCalendar";

const CustomDay = (props: PickersDayProps<Dayjs> & ICustomDayProps & {currentActiveMonth: Dayjs}) => {
  const { id, curDate, onClickDate, dateList, day, outsideCurrentMonth, currentActiveMonth, ...other } = props;

  const date = day.get("date");
  const clickedDate = curDate ? day.isSame(curDate, "date") : false;

  const memoized = useMemo(() => {
    const isCurrentPresent = dateList.includes(date);
    const ExccedFlag = day.add(1, "days").get("date") === 1;
    const isPreviousDatePresent = dateList.includes(date - 1);
    const isNextDatePresent = ExccedFlag ? false : dateList.includes(date + 1);
    const yesterday = day.subtract(1, "days");
    const tomorrow = day.add(1, "days");
    const isPrevClicked = curDate ? yesterday.isSame(curDate, "date") : false;
    const isNextClicked = curDate ? tomorrow.isSame(curDate, "date") : false;
    const isNextToday = ExccedFlag ? false : dayjs().isSame(tomorrow, "date");
    const isPrevToday = dayjs().isSame(yesterday, "date");

    const doesWeekStarts = day.isSame(day.startOf("week"), "date");
    const doesWeekEnds = day.isSame(day.endOf("week"), "date");

    const isToday = dayjs().isSame(day, "date") && currentActiveMonth && day.isSame(currentActiveMonth, "month"); 
    const isYesterday = dayjs().isSame(tomorrow, "date");
    const isTomorrow = dayjs().isSame(yesterday, "date");

    const isLastConsecutiveDate = isCurrentPresent && !isNextDatePresent;
    const isFirstConsecutiveDate = isCurrentPresent && !isPreviousDatePresent;

    return {
      isCurrentPresent,
      isPreviousDatePresent,
      isNextDatePresent,
      isPrevClicked,
      isNextClicked,
      isNextToday,
      isPrevToday,

      doesWeekStarts,
      doesWeekEnds,

      isToday,
      isTomorrow,
      isYesterday,

      isLastConsecutiveDate,
      isFirstConsecutiveDate
    };
  }, [curDate, date, dateList, day, currentActiveMonth]);

  const borderBox: SxProps = {
    display: clickedDate ? 'block' : 'none',
    position: 'absolute', 
    width: '61px', 
    height: '35px', 
    borderRadius: '10px',
    zIndex: '23 !important',
    border: '1px solid #38B000 !important'
  }

  const {
    isCurrentPresent,
    isPreviousDatePresent,
    isNextDatePresent,
    isPrevClicked,
    isNextClicked,
    isNextToday,
    isPrevToday,
    isToday,
    doesWeekStarts,
    doesWeekEnds,
    isTomorrow,
    isYesterday,

    isLastConsecutiveDate,
    isFirstConsecutiveDate,
  } = memoized;

  const radius = "10px";
  let borderTopRightRadius = isNextDatePresent && !isPreviousDatePresent ? 0 : radius;
  let borderBottomRightRadius = isNextDatePresent && !isPreviousDatePresent ? 0 : radius;
  let borderTopLeftRadius = isPreviousDatePresent && !isNextDatePresent ? 0 : radius;
  let borderBottomLeftRadius = isPreviousDatePresent && !isNextDatePresent ? 0 : radius;

  

  if (isPreviousDatePresent && isNextDatePresent) {
    borderTopRightRadius = borderBottomRightRadius = borderTopLeftRadius = borderBottomLeftRadius = "0%";
  }

  if (!isCurrentPresent || (isNextClicked && isPrevClicked)) {
    borderTopRightRadius = borderBottomRightRadius = borderTopLeftRadius = borderBottomLeftRadius = radius;
  }

  if(doesWeekEnds) {
    borderTopRightRadius = radius
    borderBottomRightRadius = radius
  }

  if(doesWeekStarts) {
    borderTopLeftRadius = radius
    borderBottomLeftRadius = radius
  }

  if(isYesterday && isCurrentPresent) {
    borderTopRightRadius = '0%'
    borderBottomRightRadius = '0%'
  }

  if(isPrevClicked && !isFirstConsecutiveDate && !doesWeekStarts) {
    borderTopLeftRadius = '0%'
    borderBottomLeftRadius = '0%'
  }

  if(isNextClicked && !isLastConsecutiveDate && !doesWeekEnds) {
    borderTopRightRadius = '0%'
    borderBottomRightRadius = '0%'
  }

  if(isTomorrow && isCurrentPresent) {
    borderTopLeftRadius = '0%'
    borderBottomLeftRadius = '0%'
  }

  let className = "";
  if (isCurrentPresent) className += `BookedDate `;
  if (clickedDate) className += `SelectedDate `;
  if (isPreviousDatePresent) className += `PrevPresent `;
  if (isNextDatePresent) className += `NextPresent `;
  if (isNextToday) className += `NextToday `;
  if (isPrevToday) className += `PrevToday `;

  return (
    <Box position='relative' display='flex' justifyContent='center'>
      <Box sx={borderBox}  />
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disableRipple
        disableTouchRipple
        disableMargin
        style={{
          backgroundColor: isCurrentPresent ? "#206A14" : undefined,
          color: "white",
        }}
        sx={{
          borderTopRightRadius: borderTopRightRadius,
          borderBottomRightRadius: borderBottomRightRadius,
          borderTopLeftRadius: borderTopLeftRadius,
          borderBottomLeftRadius: borderBottomLeftRadius,
        }}
        onClick={() => onClickDate(day, isCurrentPresent, date)}
      />
      {isToday && (
        <Box
          position="absolute"
          bottom={0}
          width="32px"
          height="4px"
          bgcolor="#F27B25"
          borderRadius="4px"
        />
      )}
    </Box>
  );
};

export default memo(CustomDay);