import { useState, useRef, useMemo, useEffect } from "react"
import dayjs, { Dayjs } from "dayjs"
import { Autocomplete, Box, SxProps, TextField } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar"
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton"
import { calendarSX, headerSX } from "./AvailabilityCalendar.sx"
import CustomDay from "./CustomDay"
import useProperties from "@/hooks/useProperties"
import Image from "next/image"
import CalendarMonth from "../../app/assets/images/AvailabilityCalendar/CalendarMonth.svg"
import ArrowIcon from "../../app/assets/images/AvailabilityCalendar/ArrowIcon.svg"
import styles from "./AvailabilityCalendar.module.css"
import { useRouter } from "next/navigation"
import { COLORS } from "@/theme/localTheme"
import { useMutation } from "react-query"
import { apiClient } from "@/apiClient/apiService"
import API_END_POINT, { ROUTES } from "@/apiClient/apiEndpoints"
import { TModalContent, TReqTypeKey } from "@/interfaces/AvailabilityCalendar"
import useUserDetails from "@/hooks/useUserDetails"
import useRoles from "@/hooks/useRoles"
import { UserDetails } from "@/interfaces/UserDetails"
var advancedFormat = require("dayjs/plugin/advancedFormat")

dayjs.extend(advancedFormat)

const bsSX: SxProps = {
  color: `${COLORS.adminAppTextPrimary}`,
  "& fieldset": {
    borderColor: "#3CA63C !important",
  },
  ".MuiFormLabel-root.Mui-focused": {
    color: COLORS.adminAppTextPrimary,
  },
  ".MuiSvgIcon-root": {
    color: "white",
  },
  " .MuiOutlinedInput-root": {
    color: "white",
  },
  width: "100%",
}

// const dateList: Array<number> = [1, 2, 3, 5, 9, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31]

const LeftArrowIcon = () => <Image src={ArrowIcon} alt={"<"} width={30} height={30} />
const RightArrowIcon = () => <Image src={ArrowIcon} alt={">"} width={30} height={30} style={{ transform: "rotate(180deg)" }} />

const days: { [key: string]: string } = {
  'Su': 'Sun',
  'Mo': 'Mon',
  'Tu': 'Tue',
  'We': 'Wed',
  'Th': 'Thu',
  'Fr': 'Fri',
  'Sa': 'Sat',
}

function formatDays(key: string) {
  return days[key] ? days[key] : key
}


// GMT to UTC
const time_difference = 63000000

const AvailabilityCalendar = () => {
  const dateItemRef = useRef<any>("")
  // const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<TModalContent>()
  const [property, setProperty] = useState<string>("")
  const [date, setDate] = useState<Dayjs | "">("")
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs())
  const [dateList, setDateList] = useState<Array<number>>([])
  const { data: properties } = useProperties()
  const router = useRouter()

  const { data: userDetailsData } = useUserDetails()
  const { isCleaner } = useRoles(userDetailsData?.data as UserDetails)

  const ModalText: Record<TReqTypeKey, string> = {
    valid: `Are you sure you want to create a cleans<br />task on`,
    override: `Please be aware that there's already another clean<br />request for same property for same date. Do you<br />want to continue creating another task?`, //
  }

  useEffect(() => {
    // onChangeMonth
    if (date) {
      // Logic whether to reset the current selected date
      const month1 = currentMonth.format("M/YY") !== (date as Dayjs).format("M/YY")
      const month2 = currentMonth.add(1, "months").format("M/YY") !== (date as Dayjs).format("M/YY")
      if (month1 && month2) {
        setDate("")
      }
    }
    updateDateList()
  }, [currentMonth])

  const propertyList = useMemo(() => {
    if (!properties?.data?.data) {
      return []
    }

    return properties.data.data
      .sort((a, b) => String(a.property_code).localeCompare(String(b.property_code)))
      .map((item) => ({
        value: item?.property_code,
        label: `(${item?.property_code}) ${item?.property_name}`,
      }))
  }, [properties])

  const postAvailCalMutation = useMutation({
    mutationFn: (data: any) => {
      return apiClient.post(`${API_END_POINT.POST[ROUTES.getAvailCalendar]}`, data)
    },
  })

  const onClickAssignTask = () => {
    setModalVisible(true)
  }

  const onClickDate = (date: Dayjs, occupied: boolean, data: any) => {
    const formatDate = date ? date.format("Do MMM YYYY") : ""
    const key = occupied ? "override" : "valid"
    const desc = `${ModalText[key]} ${!occupied ? formatDate : ""}`
    dateItemRef.current = data
    setDate(date)
    setModalContent({
      occupied,
      desc,
    })
  }

  const updateDateList = async (propertyCode?: string) => {
    if (propertyCode || property) {
      const todayMonth = currentMonth.startOf("month")
      await postAvailCalMutation.mutateAsync(
        {
          listing_name: propertyCode || property,
          start: todayMonth.unix() * 1000 + time_difference,
          end: todayMonth.add(1, "month").subtract(1, "second").unix() * 1000,
        },
        {
          onSuccess: (data: any) => {
            setDateList([...data.bookedDates])
          },
        },
      )
    }
  }

  const onChangeProperty = async (item: any) => {
    if (item) {
      const { value, label } = item
      setProperty(value)
      updateDateList(value)
    } else {
      setProperty('')
    }
  }

  const onClickSure = () => {
    const localDate = date ? date.format("DD/MM/YYYY") : ""
    // http://localhost:3000/cleaning-app/clean-status?clean_id=1245&code=LODGE14&cleaningCompanyId=145
    setModalVisible(false)
    router.back()

    setTimeout(() => {
      if (modalContent?.occupied && isCleaner) {
        const cleanId = 1165
        const code = property
        const cleaningCompanyId = 129
        router.push(`/cleaning-app/clean-status?clean_id=${cleanId}&code=${code}&cleaningCompanyId=${cleaningCompanyId}`)
      } else if (isCleaner) {
        router.push("/cleaning-app/add-clean")
      } else {
        return
      }
    }, 250)
  }

  const onClickCancel = () => {
    setModalVisible(false)
  }

  return (
    <Box className={styles.AvailabilitySidePanel}>
      <Box sx={{ width: "485px" }}>
        <Box className={styles.PropertyContainer}>
          <Box className={styles.PropertyHeader}>
            <Image src={CalendarMonth} alt="CalendarIcon" width={30} height={30} />
            {"Select Property"}
          </Box>
          <Autocomplete
            sx={bsSX}
            placeholder="Select Property"
            onChange={(e, v) => {
              if (!v) {
                setDateList([])
                setCurrentMonth(dayjs())
              }
              onChangeProperty(v)
            }}
            options={propertyList ?? []} //
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Box className={styles.AvailabilityActions}>
          <Box className={styles.AvailabilityActionsHeader}>
            <Box className={styles.ActionHeader}>
              <Image src={CalendarMonth} alt="CalendarIcon" width={30} height={30} />
              {"Availability Calendar"}
            </Box>
            {/* <Button className={styles.AssignTask} title="Assign Task" onClick={onClickAssignTask} disabled={date === "" || property === ""}>
              Assign Task
            </Button> */}
          </Box>
          <Box className={styles.LegendList}>
            <Box className={`${styles.AvailabilityFlex} ${styles.LegendItem}`}>
              <Box className={styles.Legend}></Box>
              {"Not Booked Date"}
            </Box>
            <Box className={`${styles.AvailabilityFlex} ${styles.LegendItem}`}>
              <Box className={styles.Legend}></Box>
              {"Booked Date"}
            </Box>
          </Box>
        </Box>

        <Box className={styles.CalendarContainer}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={currentMonth}
              loading={postAvailCalMutation.isLoading}
              renderLoading={() => <DayCalendarSkeleton />}
              views={["day"]}
              dayOfWeekFormatter={(day) => formatDays(day.slice(0, 2))}
              sx={{
                ...calendarSX,
              }}
              disableFuture={false}
              reduceAnimations={true}
              slots={{
                day: (props) => <CustomDay {...props} id="Calendar_1" currentActiveMonth={currentMonth} curDate={date} dateList={dateList} onClickDate={onClickDate} />,
                leftArrowIcon: LeftArrowIcon,
                rightArrowIcon: RightArrowIcon,
              }}
              slotProps={{
                calendarHeader: {
                  sx: { ...headerSX },
                },
                previousIconButton: {
                  onClick: () => {
                    setCurrentMonth((prevMonth) => prevMonth.subtract(1, "months"))
                  },
                  disabled: postAvailCalMutation.isLoading,
                },
              }}
            />
          </LocalizationProvider>

        </Box>
      </Box>

      {/* <OwnersActionModal
        open={modalVisible}
        ownerId={''}
        title={date ? `Selected Date : ${(date as Dayjs).format("Do MMM YYYY")}` : ""}
        desc={<div dangerouslySetInnerHTML={{ __html: modalContent?.desc ?? "" }}></div>}
        onClose={onClickCancel}
        cancel={{
          onCancel: onClickCancel,
          label: "Cancel",
        }}
        submit={{
          onSubmit: onClickSure,
          label: "Sure",
        }}
      /> */}
    </Box>
  )
}

export default AvailabilityCalendar
