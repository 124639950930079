import { Dayjs } from "dayjs"

export enum ModuleName {
  CLEANS = "CLEANS",
  MAINTENANCE = "MAINTENANCE",
}

export type TCardFilter = Record<ModuleName, string>

export type TAPIPayload = {
  start?: number
  end?: number
}

export type TAdvancedFilter = Array<TAdvancedFilterFields>

export type TOperatorField = "and" | "or"

export type TDropdownValueField = {
  label: string
  value: string | number
}

export type TDateRangeValueField = {
  start: Dayjs
  end: Dayjs
}
// export type TAFValueField = string

export type TAdvancedFilterFields = {
  // operator: TOperatorField
  column: string
  value: string | Array<TDropdownValueField>
  range?: TDateRangeValueField
}

export type TAdvancedFilterAPI = Record<string, string | Array<string> | TAPIPayload> | TAPIPayload
