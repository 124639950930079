import CalendarMonthColorIcon from "@/app/assets/images/AvailabilityCalendar/CalendarMonthColorIcon"
import Cleans from "../assets/images/SideBar/CleansIcon"
import Maintenance from "../assets/images/SideBar/MaintenanceIcon"

export type TNavItem = {
  id: 'cleans' | 'maintenance' | 'availability-calendar';
  icon: () => JSX.Element
  text: string
  tabs?: TNavItem[]
  path?: string
  className?: string // Styles via CSS Modules
}

export const cleansNav = {
  id: 'cleans',
  icon: Cleans,
  text: "Cleans",
  path: "/cleans",
}

export const maintenanceNav = {
  id: 'maintenance',
  icon: Maintenance,
  text: "Maintenance",
  path: "/maintenance",
}

export const availabilityCalendarNav = {
  id: "availability-calendar",
  icon: CalendarMonthColorIcon,
  text: "Availability Calendar",
  path: "/availability-calendar",
  className: "AvailabilityCalendar",
}

export const navData: { [key in string]: TNavItem[] } = {
  Task: [],
  "": []
}
