"use client"
import "./globals.css"
import { Box, StyledEngineProvider, SxProps } from "@mui/material"
import "react-toastify/dist/ReactToastify.css"
import bgLogin from "../components/assets/images/bgLogin.png"
import { QueryClient, QueryClientProvider } from "react-query"
import { usePathname } from "next/navigation"
import { ReactNode, useEffect, useState } from "react"
import AdminAppHeader from "../components/layout-components/Header"
import AdminNavBar from "../components/layout-components/NavBar"
import CustomTheme from "../components/util-components/CustomTheme"
import { CustomColors } from "../../theme/theme"
import { ToastContainer } from "react-toastify"
import SideBarTrigger from "../components/assets/images/SideBarTrigger"
import { observer } from "mobx-react"
import AppStore from "./store/AppStore"
import { APP_ENVIRONMENT } from "@/globalConfig"
import { HighlightInit } from "@highlight-run/next/client"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
})

const CollapseSX: SxProps = {
  display: "flex",
  alignItems: "center",
  position: "relative",
  height: "100%",
  "&::after": {
    content: "''",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
    borderRight: "1px solid rgba(255, 255, 255, 0.50)",
    height: "100%",
  },
  "& > div": {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%) rotate(180deg)",
    zIndex: 2,
    width: "3rem",
    height: "3rem",
    cursor: "pointer",
    "&.GetRotated": {
      transform: "translate(-50%)",
    },
  },
  "& svg": {
    width: "3rem",
    height: "3rem",
  },
}

const RootLayout = ({ children, modal }: { children: ReactNode; modal: any }) => {
  const [collapse, setCollapse] = useState(false)

  const pathname = usePathname()

  useEffect(() => {
    setCollapse(AppStore.sideBarCollapse)
  }, [])

  const onClickToggleCollapse = () => {
    AppStore.setSideBarCollapse(!collapse)
    setCollapse((x) => !x)
  }

  return (
    <>
      {APP_ENVIRONMENT !== "development" && (
        <HighlightInit
          projectId={"kev0q40d"}
          serviceName="contractor-external"
          tracingOrigins
          manualStart={false}
          disableSessionRecording
          environment={APP_ENVIRONMENT}
          consoleMethodsToRecord={["error"]}
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [],
          }}
        />
      )}
      <html>
        <head>
          <link rel="shortcut icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet" />
        </head>
        <body>
          <StyledEngineProvider injectFirst>
            <QueryClientProvider client={queryClient}>
              <CustomTheme>
                {pathname !== "/login" ? (
                  <Box
                    sx={{
                      width: "100hw",
                      height: "100vh",
                      backgroundColor: CustomColors.adminAppBgColor,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <AdminAppHeader />

                    <Box
                      sx={{
                        width: "100%",
                        height: "100vh",
                        overflow: "hidden",
                        display: "grid",
                        gridTemplateColumns: collapse ? "6rem 3rem calc(100% - 9rem)" : "17.5rem 1rem calc(100% - 18.5rem)",
                        transition: "200ms",
                      }}
                    >
                      <Box
                        sx={{
                          height: "auto",
                        }}
                      >
                        <Box>
                          <AdminNavBar collapse={collapse} />
                        </Box>
                      </Box>
                      <Box sx={CollapseSX}>
                        <Box className={collapse ? "GetRotated" : ""} sx={{ position: "relative" }} onClick={onClickToggleCollapse}>
                          {collapse ? <SideBarTrigger /> : <SideBarTrigger />}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          maxHeight: "auto",
                          overflow: "auto",
                          scrollbarWidth: "thin",
                          "& > div": {
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            margin: 0,
                            paddingLeft: collapse ? "15px" : "2rem",
                            paddingRight: "2rem",
                            paddingBottom: "2rem",
                          },
                        }}
                      >
                        {children}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundImage: `url(${bgLogin.src})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                      width: "100vw",
                      height: "100vh",
                    }}
                  >
                    {children}
                  </Box>
                )}

                <ToastContainer
                  position="top-left"
                  autoClose={1000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                  limit={2}
                />
                {modal}
              </CustomTheme>
            </QueryClientProvider>
          </StyledEngineProvider>
        </body>
      </html>
    </>
  )
}

export default observer(RootLayout)
