import { PaletteOptions } from "@mui/material"

export const CustomColors = {
  brandBlue: "#0E77DF",
  brandBlueDark: "#084887",
  menubar: "#181924",
  green: "#00BC13",
  lightGreen: "#83A302",
  red: "#A91818",
  lightRed: "rgba(169, 24, 24, 0.2)",
  fadedRed: "#cf4f4f",
  newChipBlue: "#E7F3FE",
  yellow: "#F5A70F",
  lightYellow: "rgba(228, 146, 23, 0.2)",
  highPrior: "#E46217",
  medPrior: "#E49217",
  lowPrior: "#FFD584",
  chartPrimary: " #27293C",
  black: "#000000",
  charcoal: "#4b4b4b",
  white: "#ffffff",
  lightGrey: "rgba(234, 234, 236, 0.6)",
  neonBlue: "#0F83F5",
  lightCheap: "#F5FAFF",
  lightBlue: "#B7DBFD",
  adminAppBgColor: "#1C2218",
  adminAppTextPrimary: "#FFFFFFB2",
  adminAppTextSecondary: "#E7E3FC",
  adminAppOutlineColor: "#3CA63C",
  adminAppBgColorV2: "#272D24",
  darkGreen: "#2E5319",
  blue: "#1f4066",
  primaryButton: "#1B920A",
  secondaryButton: "#2B4D1D",
}

export const StockPallette: PaletteOptions = {
  background: {
    default: "#1C2218",
    paper: "#1C2218",
  },
  primary: {
    main: "#58CA04",
  },
  secondary: {
    main: "#1B920A",
  },
  mode: "dark",
}
