export const COLORS = {
  textWhite: "#FFF",
  textOffWhite: "rgba(255, 255, 255, 0.7)",

  // Customer Module
  customerAppBgColor: "#1D2319",
  customerAppOutlineColor: "#3CA63C",
  customerAppOutlineSecondary: "rgba(255, 255, 255, 0.2)",
  customerAppTextPrimary: "#FFFFFFB2",
  customerAppPrimaryButton: "#3CA63C",
  customerAppSecondaryButton: "#2A7221",
  customerAppTertiaryButton: "#6F6F6F",

  // Surfaces
  surfaceDark: "#292A29",
  surfaceBlack: "#0A0A0A",
  surfaceTertiary: "#3E433B",

  // Admin App
  adminAppBgColor: "#1C2218",
  adminAppPrimaryBrandColor: "#38B000",
  adminAppPrimaryBrandColorTransluscent: "#38B00066",
  adminAppPrimaryBrandColorTransluscentPlus: "#38B0001A",
  adminAppTextPrimary: "#FFFFFFB2",
  adminAppTextSecondary: "#E7E3FC",
  adminAppOutlineColor: "#3CA63C",
  badgeBlue: "#0C6DFF",
  adminAppBgColorV2: "#272D24",
  darkGreen: "#2E5319",
  blue: "rgba(12, 109, 255, 0.3)",
  lightGreen: "rgba(58, 207, 1, 0.3)",
  primaryButton: "#1B920A",
  primaryButtonTransluscent: "#1B920A66",
  secondaryButton: "#2B4D1D",
  brandBlue: "#0E77DF",
  brandBlueDark: "#084887",
  menubar: "#181924",
  green: "#00BC13",
  red: "#A91818",
  newChipRed: "#B13E50",
  lightRed: "rgba(169, 24, 24, 0.2)",
  fadedRed: "#cf4f4f",
  newChipBlue: "#E7F3FE",
  yellow: "#F5A70F",
  lightYellow: "rgba(228, 146, 23, 0.2)",
  highPrior: "#E46217",
  medPrior: "#E49217",
  lowPrior: "#FFD584",
  chartPrimary: " #27293C",
  black: "#000000",
  charcoal: "#4b4b4b",
  white: "#ffffff",
  lightGrey: "rgba(234, 234, 236, 0.6)",
  neonBlue: "#0F83F5",
  lightCheap: "#F5FAFF",
  lightBlue: "#B7DBFD",
  chipCompleted: "rgba(204, 255, 0, 0.5)",
  tableBorder: "#3e433b",
  divider: "#3B4835",
  errorState: "#FE4646",
  errorStateTransluscent: "#FE464666",
  surfaceSecondary: "#1D1D1D",
  commentUserName: "#FFB400",
}
