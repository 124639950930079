// @ts-ignore // No Type Decelration Avail
import { makeAutoObservable } from "mobx"
// @ts-ignore // No Type Decelration Avail
import { makePersistable, isHydrated, clearPersistedStore } from "mobx-persist-store"
// import { EncryptStorage } from "encrypt-storage"
import { ModuleName, TAdvancedFilter, TAdvancedFilterAPI } from "./AppStore.types"

// const encryptStorage: any = new EncryptStorage('8PML9axPi0')

class AppStore {
  device: 0 | 1 = 1 // Mobile or Desktop
  sideBarCollapse: boolean = false
  counterCardFilter: Record<ModuleName, string> = {
    [ModuleName.CLEANS]: "All Time",
    [ModuleName.MAINTENANCE]: "All Time",
  } // Filter State

  // [{ operator: "and", column: "", value: "" }]
  advancedFilterDef: TAdvancedFilter = [{ column: "", value: "" }]
  advancedFilter: Record<ModuleName, TAdvancedFilter> = {
    // Advanced Filter State / Used for Prefilling
    [ModuleName.CLEANS]: this.advancedFilterDef,
    [ModuleName.MAINTENANCE]: this.advancedFilterDef,
  }

  advancedFilterCache: Record<ModuleName, TAdvancedFilterAPI> = {
    // Advanced Filter State / Used for Prefilling
    [ModuleName.CLEANS]: {},
    [ModuleName.MAINTENANCE]: {},
  }

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: "AppStore_markStore_RecoveryCache_Ext",
      properties: ["sideBarCollapse", "counterCardFilter", "advancedFilter", "advancedFilterCache"],
      // storage: encryptStorage,
      storage: typeof window !== "undefined" ? window.localStorage : undefined,
      version: 1,
    })
  }

  isStoreHydrated = (): boolean => isHydrated(this)
  clearStoredData = async () => {
    await clearPersistedStore(this)
  }

  setDevice = (device: 0 | 1) => (this.device = device)
  setSideBarCollapse = (state: boolean) => (this.sideBarCollapse = state)
  setCounterCardFilter = (state: Record<string, string>) => (this.counterCardFilter = state)

  setAdvancedFilter = (moduleName: ModuleName, filters: TAdvancedFilter) => {
    this.advancedFilter[moduleName] = filters
  }

  setAdvancedFilterCache = (moduleName: ModuleName, filters: TAdvancedFilterAPI) => {
    this.advancedFilterCache[moduleName] = filters
  }
}

export default new AppStore()
