"use client"
/* eslint-disable @next/next/no-img-element */
import React from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import { CustomColors } from "../../../theme/theme"
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts"
import LogoutIcon from "@mui/icons-material/Logout"
import useUserDetails from "@/hooks/useUserDetails"
type StaticImageData = {
  src?: string
  height: number
  width: number
  blurDataURL?: string
}

function AvatarMenu() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const accessToken = useReadLocalStorage("accessToken")
  const [removeToken, setRemoveToken] = useLocalStorage("accessToken", accessToken)

  const handleLogout = () => {
    setRemoveToken(null)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const { data: userDetailsData } = useUserDetails()

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            sx={{ height: "2.5rem", width: "2.5rem" }}
            alt="Profile Avatar"
            src={`https://ui-avatars.com/api/?background=1B920A&color=fff&name=${userDetailsData?.data?.data.Full_Name}`}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          mt: "2rem",
          "& .MuiMenu-list": {
            p: 0,
          },
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "#272D24",
            filter: " invert(20%)",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={handleCloseUserMenu}
          sx={{
            backgroundColor: "#272D24",
            p: "0.75rem",
            borderBottom: "1px solid #636363",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              backgroundColor: "#272D24",
            }}
            onClick={handleLogout}
          >
            <Typography
              sx={{
                mr: "1rem",
                fontWeight: "500",
                color: CustomColors?.adminAppTextPrimary,
              }}
            >
              Logout
            </Typography>
            <LogoutIcon fontSize="small" />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}
export default AvatarMenu
