"use client"
import { Box, InputAdornment, TextField } from "@mui/material"
import useUserDetails from "@/hooks/useUserDetails"

import { useRouter } from "next/navigation"
import SearchIcon from "@mui/icons-material/Search"
import AvatarMenu from "../assets/AvatarMenu"
import SergoLogo from "../assets/SergoLogo"
import { COLORS } from "../../../theme/localTheme"
import { UserDetails } from "@/interfaces/UserDetails"
import useRoles from "@/hooks/useRoles"

export type HEADER_PROPS = {
  headerText?: string | undefined
  hotelName?: string
  reservationNumber?: string
  withPropertyInfo?: boolean
  withPersonalInfo?: boolean
}

export default function AdminAppHeader() {
  const router = useRouter()
  const { data: userDetailsData } = useUserDetails()
  const { isCleaner, isMaintain } = useRoles(userDetailsData?.data as UserDetails)

  const onClickLogoIcon = () => {
    if (isCleaner) {
      router.push("/cleans")
    } else {
      router.push("/maintenance")
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          backgroundColor: COLORS.adminAppBgColor,
          padding: "1rem",
          px: "4rem",
          height: "auto",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "100px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "18%" }}>
          <Box onClick={onClickLogoIcon} sx={{ cursor: "pointer" }}>
            <SergoLogo width="8rem" height="4rem" />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mr: "1rem",
            "& :hover": {
              cursor: "pointer",
            },
          }}
        >
          <AvatarMenu />
        </Box>
      </Box>
    </>
  )
}
