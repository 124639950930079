import { SvgIcon } from "@mui/material"

const MaintenanceIcon = () => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
        <path
          d="M10.005 2.80053L18.0957 9.0167V19.6557H1.91436V9.0167L10.005 2.80053ZM10.005 0.382568L0 8.07974V21.58H20V8.07974L10.005 0.382568Z"
          fill="inherit"
        />
        <mask id="mask0_348_951" maskUnits="userSpaceOnUse" x="3" y="6" width="14" height="13">
          <rect x="3.57031" y="6.02319" width="12.6779" height="12.897" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_348_951)">
          <path
            d="M9.28228 15.2668H10.5501V11.9351H9.28228V15.2668ZM9.91617 11.0082C10.1099 11.0082 10.2706 10.9431 10.3985 10.8131C10.5259 10.6834 10.5897 10.52 10.5897 10.323C10.5897 10.126 10.5259 9.96242 10.3985 9.83238C10.2706 9.70269 10.1099 9.63785 9.91617 9.63785C9.72248 9.63785 9.56189 9.70269 9.43441 9.83238C9.30657 9.96242 9.24266 10.126 9.24266 10.323C9.24266 10.52 9.30657 10.6834 9.43441 10.8131C9.56189 10.9431 9.72248 11.0082 9.91617 11.0082ZM9.91617 18.1015C9.14141 18.1015 8.41948 17.9537 7.75037 17.6582C7.08126 17.3626 6.49808 16.9619 6.00082 16.4561C5.50322 15.9498 5.10915 15.3564 4.81861 14.6757C4.52808 13.9951 4.38281 13.2606 4.38281 12.4725C4.38281 11.6844 4.52808 10.9499 4.81861 10.2693C5.10915 9.58859 5.50322 8.99515 6.00082 8.48895C6.49808 7.9831 7.08126 7.5824 7.75037 7.28684C8.41948 6.99128 9.14141 6.84351 9.91617 6.84351C10.6909 6.84351 11.4129 6.99128 12.082 7.28684C12.7511 7.5824 13.3344 7.9831 13.832 8.48895C14.3293 8.99515 14.7232 9.58859 15.0137 10.2693C15.3043 10.9499 15.4495 11.6844 15.4495 12.4725C15.4495 13.2606 15.3043 13.9951 15.0137 14.6757C14.7232 15.3564 14.3293 15.9498 13.832 16.4561C13.3344 16.9619 12.7511 17.3626 12.082 17.6582C11.4129 17.9537 10.6909 18.1015 9.91617 18.1015ZM9.91617 16.6775C11.0783 16.6775 12.0577 16.2721 12.8543 15.4614C13.6512 14.651 14.0497 13.6547 14.0497 12.4725C14.0497 11.2903 13.6512 10.2938 12.8543 9.48309C12.0577 8.67273 11.0783 8.26755 9.91617 8.26755C8.75403 8.26755 7.77467 8.67273 6.97808 9.48309C6.18113 10.2938 5.78266 11.2903 5.78266 12.4725C5.78266 13.6547 6.18113 14.651 6.97808 15.4614C7.77467 16.2721 8.75403 16.6775 9.91617 16.6775Z"
            fill="inherit"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default MaintenanceIcon
