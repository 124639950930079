import { SidePanelProps } from "@/interfaces/SidePanel"
import { Drawer } from "@mui/material"

const SidePanel = ({ open, onClose, children }: SidePanelProps) => {
  
  const onCloseDrawer = () => {
    onClose?.()
  }

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onCloseDrawer}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(28, 34, 24, 70%)",
          },
        },
      }}
    >
      {children}
    </Drawer>
  )
}

export default SidePanel
