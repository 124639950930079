import { createContext, ReactNode, useMemo, useState } from "react"
import { createTheme, PaletteMode, ThemeProvider } from "@mui/material"
import { CustomColors } from "../../../theme/theme"

export const ColorModeContext = createContext({
  mode: "dark",
})
interface Props {
  children?: ReactNode
}

export default function CustomTheme({ children }: Props) {
  const [mode, setMode] = useState<PaletteMode>("dark")

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1B920A",
      },
      secondary: {
        main: "#58CA04",
      },
      background: {
        default: "#1C2218",
        paper: "#1C2218",
      },

      mode: mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            border: CustomColors?.adminAppOutlineColor,
          },
        },
      },
    },
    CustomColors,
  })

  return (
    <ColorModeContext.Provider value={{ mode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  )
}
