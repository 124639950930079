import { useQuery } from "react-query"
import { apiClient } from "../apiClient/apiService"
import ApiEndpoints, { ROUTES } from "../apiClient/apiEndpoints"
import { TPropertyList } from "@/interfaces/TProperties"

type TProperties = {
  enabled?: boolean
}

const useProperties = (props?: TProperties) => {
  const { enabled = true } = props ? props : {}
  return useQuery<{ data: TPropertyList }>({
    queryKey: ["Properties"],
    queryFn: () => {
      return apiClient.get(ApiEndpoints.GET[ROUTES.getProperty])
    },
    enabled: enabled,
  })
}

export default useProperties
