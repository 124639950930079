var GenerateNick = (nick?: string) => {
  if (nick) {
    if (nick.length > 2) {
      nick = nick.replace(/[^a-zA-Z0-9\s]/g, "")

      if (nick.includes(" ")) {
        var split = nick?.split(" ")
        nick = ""
        split.map((x) => (nick += x.charAt(0)))
      }
      nick = nick.substring(0, 2)
    }
    return nick.toUpperCase()
  } else return ""
}

const debounce = (func: any, delay: number) => {
  let timerId: any
  return function (...args: any) {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args)
      timerId = null
    }, delay)
  }
}

const dynamicDropdownHash: Record<string, any> = {
  "additional-service/internal-employees": {
    label: "Full_Name",
    value: "Full_Name",
  },
  "cleanerapp/cleaning_companies_manager": {
    label: "Full_Name",
    value: "id",
  },
  "property_reservationDetails/property_replacement_types": {
    label: "Replacement_Type",
    value: "Replacement_Type",
  },
  "property_reservationDetails/replacement_items": {
    label: "item",
    value: "sell_price",
    sub_value: "item"
  },
  "additional-service/assigned-employees": {
    label: "Full_Name",
    value: "Full_Name",
  },
  "ticket/company_list": {
    label: "name",
    value: "id",
  },
  "ticket/company_user_list": {
    label: "Full_Name",
    value: "id",
  },
}


function isStringParsable(str: string): boolean {
  try {
    JSON.parse(str)
    return true
  } catch {
    return false
  }
}


function checkFileSize(max: number) {
  return function checkFilesForSize(files?: unknown | File | [File]): boolean {
    let valid = true
    if (files) {
      if (!Array.isArray(files)) {
        files = [files]
      }
      if (Array.isArray(files)) {
        files.every((file) => {
          const size = file.size / 1024
          if (size > max) {
            valid = false
          }
          return valid
        })
      }
    }
    return valid
  }
}

function isObjectEmpty(obj: Record<string, unknown>): boolean {
  return Object.keys(obj).length === 0
}



export { GenerateNick, debounce, dynamicDropdownHash, checkFileSize, isStringParsable, isObjectEmpty }
